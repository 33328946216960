@import "../scss/bootstrap.scss";

$main-color: #FD1E6F;
$main-light-color: #ff649d;
$main-more-light-color: #ff649d77;
$main-dark-color: #c30044;

p {
    text-indent: .5rem;
    margin-bottom: .2rem !important;
}

body {
    overflow-x: hidden;

    .navbar-toggler {
        border: 1px solid $main-color;
    }

    .container {
        padding: 3rem 1rem;

        .nav-link {
            color: black;
            cursor: pointer;
            margin-left: .5rem;

            &:hover {
                margin-top: -1px;
                border-bottom: 1px solid $main-color;
            }
        }

        .navbar-collapse {

            &.collapse {

                &.show {
                    .nav-link {
                        text-align: center;
                        padding-bottom: .5rem;
                    }
                }
            }
        }

        .home {
            img {
                width: 100%;
                height: 200px;
                object-fit: cover;
                margin-top: 3rem;
                margin-bottom: 3rem;
            }

            .title {
                font-weight: bold;
                font-size: 1.5rem;
                margin-top: 0rem;

                &.sub {
                    font-size: 1.1rem;
                    color: $main-light-color;
                    margin-top: 1.5rem;
                    margin-bottom: .6rem;
                }

                &.item {
                    font-size: 1rem;
                    margin-top: 0rem;
                    margin-bottom: 0rem;
                }
            }
        }

        .about {
            @media (max-width: 768px) {
                .logo{
                    display: none;
                }
            }
            .col-12 {
                .bg {
                    background-color: $main-color;
                    width: 158px;
                    height: 200px;
                    margin-left: 10px;
                    border-radius: 1rem;
                }
            }

            .title {
                font-weight: bold;
                font-size: 1.5rem;
                margin-top: 0rem;
                margin-bottom: 3rem;

                &.sub {
                    font-size: 1.1rem;
                    color: $main-light-color;
                    margin-top: 1.5rem;
                    margin-bottom: .6rem;
                }
            }

            .date {
                display: block;
                font-size: .8rem;
                color: #888;
            }

            img {
                height: 200px;
                border-radius: 1rem;
                margin-top: -190px;

            }

            .logo {
                position: absolute;
                top: 62%;
                right: 18%;
                opacity: .1;
                transform: scale(8) rotate(30deg);

                >div {
                    border: 3px solid $main-color;
                    width: 40px;
                    height: 40px;

                    span {
                        font-size: .9rem;
                        color: $main-color;
                        padding-top: .3rem;
                        display: inline-block;
                        width: 100%;
                        text-align: center;
                    }

                    transition: unset;
                }
            }
        }

        .career {

            @media (max-width: 768px) {
                .bg{
                    display: none;
                }
            }
            blockquote {
                margin-left: 3rem;
            }

            .badge {
                margin-right: .5rem;
                margin-bottom: .3rem;
            }

            position:relative;

            .title {
                font-weight: bold;
                font-size: 1.5rem;
                margin-top: 0rem;

                &.sub {
                    font-size: 1.1rem;
                    color: $main-light-color;
                    margin-top: 2.5rem;
                    margin-bottom: .6rem;

                    .date {
                        display: block;
                        font-size: .8rem;
                        color: #888;
                    }
                }
            }

            img {
                width: 100%;
                height: 200px;
                object-fit: cover;
                margin-bottom: 3rem;

                &.bg {
                    position: absolute;
                    width: 350px;
                    height: unset;
                    opacity: 0.1;
                    right: 0%;
                    margin-right: -20%;
                    box-shadow: 2px 2px 2px #888;

                    &:nth-child(1) {
                        top: 15%;
                    }

                    &:nth-child(2) {
                        top: 40%;
                    }

                    &:nth-child(3) {
                        top: 75%;
                    }
                }
            }

            .summary {
                .data {
                    min-height: 155px;

                    &.count {
                        padding: 3rem 0;
                    }
                }
            }
        }

        .study {
            img.main {
                width: 100%;
                height: 200px;
                object-fit: cover;
                margin-bottom: 3rem;
            }

            .title {
                font-weight: bold;
                font-size: 1.5rem;
                margin-top: 0rem;

                &.sub {
                    font-size: 1.1rem;
                    color: $main-light-color;
                    margin-top: 1.5rem;
                    margin-bottom: .6rem;
                }

                &.item {
                    font-size: 1rem;
                    margin-top: 0rem;
                    margin-bottom: 0rem;
                }
            }

            .img-box {
                padding: 2rem 0;

                img {
                    max-height: 70px;
                }
            }
        }

        .post {}

        .posts {
            ul{
                margin: 0 auto;
            }

            .title {
                font-weight: bold;
                font-size: 1.2rem;
                margin-top: 0rem;
            }
        }
    }

    .logo {
        >div {
            border: 3px solid $main-color;
            width: 40px;
            height: 40px;

            span {
                font-size: .9rem;
                color: $main-color;
                padding-top: .2rem;
                display: inline-block;
                width: 100%;
                text-align: center;
            }

            transition: transform 2s;

            &:hover {
                transform: rotate(360deg);
            }
        }
    }

    .contact {
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
        font-weight: bold;
        text-align: center;

        .btn {
            margin-top: 1rem;
        }
    }

    .footer {
        font-weight: bold;
        text-align: center;
    }

    .tool {
        display: none;
        position: fixed;
        cursor: pointer;
        top: 0px;
        right: -2px;
        padding: 1rem 1rem .7rem .3rem;
        border: 2px solid $main-color;
        border-top-left-radius: .3rem;
        border-bottom-left-radius: .3rem;
        background-color: white;
        z-index: 999;

        .list {
            width: 0px;
            display: none;
            transition: all .3s;

            ul {
                list-style: none;
            }

            &.show {
                display: block;
                text-align: center;
                width: 100vw;
                transition: all .3s;
            }
        }
    }

    .tag {
        background: #bbb;
        border-radius: 3px 0 0 3px;
        color: white;
        display: inline-block;
        height: 26px;
        line-height: 26px;
        padding: 0 20px 0 23px;
        position: relative;
        margin: 1px 10px 1px 0;
        text-decoration: none;
        -webkit-transition: color 0.2s;
        font-size: .8rem;
        cursor: pointer;

        &.sm{
            font-size: .6rem;
            height: 22px;
            line-height: 22px;

            &::before {
                background: #fff;
                border-radius: 10px;
                box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
                content: '';
                height: 4px;
                left: 10px;
                position: absolute;
                width: 6px;
                top: 10px;
            }
    
            &::after {
                background: #fff;
                border-bottom: 12px solid transparent;
                border-left: 8px solid #bbb;
                border-top: 10px solid transparent;
                content: '';
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        &.fit {
            display: block;
            width: fit-content;
        }

        &::before {
            background: #fff;
            border-radius: 10px;
            box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
            content: '';
            height: 6px;
            left: 10px;
            position: absolute;
            width: 6px;
            top: 10px;
        }

        &::after {
            background: #fff;
            border-bottom: 14px solid transparent;
            border-left: 10px solid #bbb;
            border-top: 13px solid transparent;
            content: '';
            position: absolute;
            right: 0;
            top: 0;
        }

        &:hover{
            background-color: $main-light-color;
            color: white;

            &::after {
                border-left-color: $main-light-color;
            }
        }

        &.active{
            background-color: $main-light-color;
            color: white;

            &::after {
                border-left-color: $main-light-color;
            }
        }

        &.big {
            background-color: $main-light-color;
            font-size: 1rem;

            &::after {
                border-left-color: $main-light-color;
            }
        }
    }
}
.styles_kPagination__1qVvD {

    padding: .5rem 0 !important;
    .styles_kPaginationListPage__2_6Ph{
        margin: 0 auto !important;
    }
}



